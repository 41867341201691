import { Link } from "react-router-dom";

function Footer() {
  const scrollToTop = () => {
    window.scrollTo({ top: (0, 0), behavior: "smooth" });
  };
  return (
    <>
      <footer>
        <div className="container">
          <div className="footer-content">
            <ul className="footer-content__1">
              <li>
                <span>Tramites Seguros</span> USA
              </li>
              <li>
                Somos una empresa de gestión de tramites, le ayudamos a tramitar y realizar las solicitudes
                correctamentes y de forma segura. 
              </li>
              <li>
                <a href="tel:+1 (786)481-9134" target="_blank" rel="noopener noreferrer">
                  <i className="fa-solid fa-phone"></i> &nbsp; +1 (786)481-9134
                </a>
              </li>
              <li>
                <a href="tel: +1 (786)332-7102" target="_blank" rel="noopener noreferrer">
                  <i className="fa-solid fa-phone"></i> &nbsp; +1 (786)332-7102
                </a>
              </li>
              <li>
                <a href="#Cookies" target="_blank" rel="noopener noreferrer">
                  <i className="fa-solid fa-phone"></i> &nbsp; Politicas de privacidad
                </a>
              </li>

              <li>
               
                  <i className="fa-solid fa-envelope"></i>
                  &nbsp;<h7>tramitesegurosusa@gmail.com</h7>
                
              </li>
            </ul>

            <ul className="footer-content__2">
              <li>Ubícanos</li>
              <li>
                <a href="#home">Miami</a>
              </li>
              <li>
                <a href="https://www.instagram.com/tramitesegurosusa/" target="_blank" rel="noopener noreferrer">Instagram</a>
              </li>
              <li>
                <a href="https://www.facebook.com/profile.php?id=100093806467780&mibextid=ZbWKwL">Facebook</a>
              </li>
              
            </ul>

            <ul className="footer-content__2">
              <li>Horarios de Trabajo</li>
              <li>Mon - Fri: 9:00AM - 9:00PM</li>
              <li>Sat: 9:00AM - 19:00PM</li>
              <li>Sun: Closed</li>
            </ul>

            <ul className="footer-content__2">
              <li>Contáctanos</li>
              <li>
                <p>Dejanos tus datos y te contáctamos a la brevedad</p>
              </li>
              
              <li>
                <div className="btn">
                <Link className="btn-cont" onClick={scrollToTop} to="/contact">Contactar</Link>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
