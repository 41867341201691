import Footer from "../components/Footer";
import HeroPages from "../components/HeroPages";
// import PlanTrip from "../components/PlanTrip";
import SegurosMain from "../images/Seguros/seguros-images.png";
import SegurosMain2 from "../images/Seguros/policy.png";
// import SegurosMain3 from "../images/Seguros/dent.jpg";
import SegurosMain4 from "../images/Seguros/vision.png";
import SegurosMain5 from "../images/Seguros/dentista.png";
// import SegurosMain3 from "../images/Seguros/doct.png";
import Box1 from "../images/Seguros/icon1.png";
import Box2 from "../images/Seguros/icon2.png";
import Box3 from "../images/Seguros/icon3.png";
import Box4 from "../images/Seguros/icon4.png";
import Box5 from "../images/Seguros/icon5.png";
import React, {useEffect} from "react";
import Aos from "aos";
import "aos/dist/aos.css"



function Seguros() {
  
  useEffect(()=>{
    Aos.init()
  },[])

  return (
    <>


    
<HeroPages name="Seguros" />

      <section className="seguros-page">
        <div className="container-seguros">
        
          <div className="seguros-main">

            <img
              className="seguros-main__img1" data-aos="fade-right" data-aos-duration="4000"
              src={SegurosMain}
              alt="car-renting"
            />
            <div className="seguros-main__text"data-aos="fade-down"data-aos-duration="4000">
              <h2>Obamacare</h2>
              <h3>Seguros de salud de bajo costo</h3>
              <p>
              La  Ley de Cuidados Asequibles (por sus siglas en ingles ACA), 
              se le conoce como Obamacare, ya que fue promulgada en marzo de 2010 
              por el presidente Barack Obama, permite hacer que los seguro de salud
              sean accesible a más personas porque los aplicantes reciben un adelanto 
              de créditos fiscales que pagara por su seguro médico y hará que tenga una 
              reducción de la prima mensual. 4 de cada 5 personas paga menos de $10 al mes (*si califican) 

              </p>
              <h3>¿Que puede cubrir?</h3> <br/>
              <div className="seguros-main__text__icons" data-aos="fade-left" data-aos-duration="4000">
                <div className="seguros-main__text__icons__box">
                  <img src={Box1} alt="car-icon" />
                  <span>
                    <p>Servicios <br/> Preventivos</p>
                  </span>
                </div>
                <div className="seguros-main__text__icons__box">
                  <img src={Box2} alt="car-icon" />
                  <span>
                    
                    <p>Laboratorios</p>
                  </span>
                </div>
                <div className="seguros-main__text__icons__box">
                  <img src={Box3} alt="car-icon" className="last-fk" />
                  <span>
                    
                    <p>Emergencias</p>
                  </span>
                </div>
                <div className="seguros-main__text__icons__box">
                  <img src={Box4} alt="car-icon" />
                  <span>
                    <p>Hospitalización</p>
                  </span>
                </div>
                <div className="seguros-main__text__icons__box">
                  <img src={Box5} alt="car-icon" />
                  <span>
                    
                    <p>Cirugias</p>
                  </span>
                </div>
              </div>
              {/* <a href="#form_section">
                  SOLICITA EL SERVICIO &nbsp;
                  <i className="fa-solid fa-angle-right"></i>
                </a>  */}
            </div>
          </div>

<section className="seguros2" data-aos="fade-right" data-aos-duration="4000">
          <div className="container-seguros2">
          <div className="seguros-main">
          <img
              className="seguros-main__img"
              src={SegurosMain2}
              alt="car-renting"
            />



<div className="seguros-main__text2">

  <h2>Seguros <font className="title-2" color="#020228">privados</font> individuales</h2>
  <h3>¿No tienes un estatus migratorio y tus ingresos son altos?</h3>
  <p>
  Los seguros privados son una excelente alternativa, tienen amplia cobertura 
  de hasta $2.000.000 de dólares, no dependen en lo absoluto del ingreso del hogar 
  o del estatus migratorio, en este caso la persona es calificada 
  por su edad y condiciones de salud. 

  </p>
  {/* <a href="#form_section">
                  SOLICITA EL SERVICIO &nbsp;
                  <i className="fa-solid fa-angle-right" ></i>
                </a>  */}
</div>
</div>
</div>
</section>
<section className="choose-section-3">
<div className="container">
          <div className="choose-container-3">
            <div className="text-container-seguros-3">
            <div className="all-tramitesmig__box " data-aos="fade-right" data-aos-duration="4000">
              <div className="text-container-3__left-seguros">
                
                <h2>Planes <font className="title-4">médicos</font> de descuento</h2>
                <p>
                El plan médico no es un seguro de salud, pero tiene grandes 
                beneficios comparado con no tener nada de cobertura, con este 
                plan obtienen el acceso a una amplia red de médicos, dentistas 
                y especialistas a un menor costo. No en todos los estados 
                encuentra planes médicos disponibles.
                </p>
                {/* <a href="#form_section">
                  SOLICITA EL SERVICIO &nbsp;
                  <i className="fa-solid fa-angle-right"></i>
                </a>  */}
                </div>
              </div>
              <div className="choose-container-3">
              <div className="text-container-3__right-seguros">
              <div className="all-tramitesmig__box " data-aos="fade-left" data-aos-duration="4000">
             
                  <h2><font className="title-3">Seguros</font> de vida</h2>

                  <p>
                    Si eres cabeza de familia y personas que dependen de ti, 
                    si te compraste una casa, un seguro de vida te permitirá 
                    proveer de recursos económicos a los tuyos en caso de que ya no estés.
                    <br/>
                    <br/>Pero además te puede proporcionar beneficio de la cobertura 
                    mientras usted vive, si sufre una enfermedad terminal, crónica 
                    o crítica, o una lesión crítica, que califiquen. Adelanto de la 
                    suma asegurada, Porque las enfermedades te detienen pero nuestros 
                    gastos se mantienen Puede usar el beneficio para cualquier propósito.
                  </p>

                  {/* <a href="#form_section">
                  SOLICITA EL SERVICIO &nbsp;
                  <i className="fa-solid fa-angle-right"></i>
                </a>  */}

                  </div>

                      
                        </div>
                      </div>
                  </div>
                </div>
            </div>
          </section>



<section className="choose-section-4">
        <div className="container">
          <div className="choose-container-4">
            {/* <img
              className="choose-container__img"
              src={MainImg}
              alt="car_img"
            /> */}
            <div className="text-container-4" data-aos="fade-right" data-aos-duration="4000">
              <div className="text-container-4__left">
                
                <h2>Seguros de Visión</h2>
                <p>
                Los planes de visión Son un complementario 
                a un seguro médico y te brindan toda la ayuda 
                que necesitas para controlar la salud de tus ojos, 
                como exámenes de la vista y anteojos, no sólo podrá 
                pagar esos costosos exámenes oculares y las pruebas 
                de diagnóstico, sino que también pueden ayudar a detectar 
                ciertas patologías en una fase temprana, evitando así 
                graves consecuencias si se tratan a tiempo. 
                </p>
                {/* <a href="#form_section">
                  SOLICITA EL SERVICIO &nbsp;
                  <i className="fa-solid fa-angle-right"></i>
                </a>  */}
              </div>
              <div className="text-container-4__right">
                <div className="text-container-4__right__box" data-aos="flip-left" data-aos-duration="4000">
                  <img className="img-vision" src={SegurosMain4} alt="car-img" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="choose-container-5">
            {/* <img
              className="choose-container__img"
              src={MainImg}
              alt="car_img"
            /> */}
            <div className="text-container-5">
            <div className="text-container-5__right">
                <div className="text-container-5__right__box" data-aos="fade-right" data-aos-duration="4000">
                  <img className="img-dent" src={SegurosMain5} alt="" />
                </div>
              </div>
              <div className="text-container-5__left" data-aos="fade-left" data-aos-duration="4000">
                
                <h2>Seguros <font className="title-6"><br/> Dentales</font></h2>
                <p>
                Los planes de visión Son un complementario 
                a un seguro médico y te brindan toda la ayuda 
                que necesitas para controlar la salud de tus ojos, 
                como exámenes de la vista y anteojos, no sólo podrá 
                pagar esos costosos exámenes oculares y las pruebas 
                de diagnóstico, sino que también pueden ayudar a detectar 
                ciertas patologías en una fase temprana, evitando así 
                graves consecuencias si se tratan a tiempo. 
                </p>
                {/* <a href="#form_section">
                  SOLICITA EL SERVICIO &nbsp;
                  <i className="fa-solid fa-angle-right"></i>
                </a>  */}
              </div>
              
            </div>
          </div>
        </div>
      </section>




          <section className="choose-section-6" data-aos="fade-up" data-aos-duration="4000">
<div className="container">
          <div className="choose-container-6">
            {/* <img
              className="choose-container__img"
              src={MainImg}
              alt="car_img"
            /> */}
            <div className="text-container-seguros-6">
              <div className="text-container-6__left-seguros">
                <h3>Prepárese para imprevistos.</h3>
                <h2>Gastos Finales </h2>
                <p>
                Planifica ahora para el mañana, porque sabemos 
                lo que sucederá con certeza pero no cuando y 
                con un seguro de gastos finales se van a cubrir 
                los  gastos funerarios cuando una persona fallece. 
                </p>
                {/* <a  href="#form_section">
                  SOLICITA EL SERVICIO &nbsp;
                  <i className="fa-solid fa-angle-right"></i>
                </a>  */}
              </div>
                  </div>
                </div>
            </div>
          </section>
         
        
</div>





{/* <Formulario de Solicitud /> */}

<section id="form_section">

<div className="servicios-content__title">
               
               <h2>Solicita el Servicio</h2>
               <p>
                 LLena el formulario con tus datos y te contactaremos a la brevedad
               </p>
             </div>

             
          <form class="row g-3" action="https://formsubmit.co/tramitesegurosUSA@gmail.com" method="POST">
               <div class="col-md-6">
                 <label for="inputname" class="form-label">Nombre</label>
                 <input type="name" name="Nombre"  class="form-control" id="inputname" />
               </div>
               <div class="col-md-6">
                 <label for="inputlastname" class="form-label">Apellido</label>
                 <input type="lastname" name="Apellido" class="form-control" id="lastname" />
               </div>
               <div class="col-md-6">
                 <label for="inputEmail" class="form-label">Correo</label>
                 <input type="email" name="Email" class="form-control" id="email" />
               </div>
               <div class="col-md-6">
                 <label for="inputAddress" class="form-label">Estado</label>
                 <input type="text" name="Estado" class="form-control" id="inputAddress" placeholder="" />
               </div>
               <div class="col-md-6">
                 <label for="inputAddress2" class="form-label">Ciudad</label>
                 <input type="text" name="Ciudad" class="form-control" id="inputAddress2" placeholder="" />
               </div>
               <div class="col-md-6">
                 <label for="inputCity" class="form-label">Telefono</label>
                 <input type="text" name="Telefono" class="form-control" id="inputCity" />
               </div>
               <div class="col-md-4">
                 <label for="inputState" class="form-label">Servicio Solicitado</label>
                 <select id="inputState" name="Tramite" class="form-select">
                  <option selected>Selecione un servicio</option>
                   <option value="Obamacare">Obamacatre</option>
                   <option  value="Seguros privados individuales">Seguros privados individuales</option>
                   <option  value="Planes medicos de descuento">Planes medicos de descuento</option>
                   <option  value="Seguros de vida">Seguros de vida</option>
                   <option  value="Seguros de visión">Seguros de visión</option>
                   <option  value="Seguros Dentales">Seguros Dentales</option>
                   <option  value="Gastos Finales">Gastos Finales</option> 

                 </select>
               </div>
               <div class="col-12">
                 <div class="form">
                 </div>
               </div>
               <div class="col-12">
                 <button type="submit" class="btn btn-primary">Enviar</button>
                 <input type="hidden" name="_next" value="https://tramitesseguro.com/#/seguros"/>
                <input type="hidden" name="_captcha" value="false"/>
               </div>
             </form>
           
       </section>
      </section>
      <div className="book-banner">
        <div className="book-banner__overlay"></div>
        <div className="container">
          <div className="text-content">
            <h2>Contactanos y te ayudamos a gestionar el seguro que mejor se adapte a ti</h2>
            <span>
              <i className="fa-solid fa-phone"></i>
              <h3>+1(786)481-9134</h3>
            </span>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Seguros;
