import Img1 from "../images/servicios/parol.png";
import { Link } from "react-router-dom";
import Img2 from "../images/servicios/permission.png";
import Img3 from "../images/servicios/home-address.png";
import Img4 from "../images/servicios/tps.png";
import Img5 from "../images/servicios/family.png";
import Img6 from "../images/servicios/asilo.png";
import Img7 from "../images/servicios/res.png";
import Img8 from "../images/servicios/visa.svg";
import Img9 from "../images/servicios/citizen.png";
import React, {useEffect} from "react";
import Aos from "aos";
import "aos/dist/aos.css"

 



function Tramitesmig() {
  const scrollToTop = () => {
    window.scrollTo({ top: (0, 0), behavior: "smooth" });
  };


  useEffect(()=>{
    Aos.init()
  },[])

  return (
    
    <>
      <section className="tramitesmig-section">
        <div className="container">
          <div className="tramitesmig-content">
            <div className="tramitesmig-content__title" data-aos="fade-right" data-aos-duration="4000">
              
              <h2>Tramites Migratorios</h2>
              <p>
              Te ayudamos a gestionar los tramites migratorios que necesites, 
              de forma segura y responsable.
          
              </p>
              <div className="text-container__left-2">
              <Link className="btn-cont" onClick={scrollToTop} to="/contact">
                  CONTACTANOS &nbsp;
                  <i className="fa-solid fa-angle-right"></i>
                </Link>
                </div>
                {/* <div className="text-container__left-3">
              <a href="#form_section">
                  SOLICITAR SERVICIO &nbsp;
                  <i className="fa-solid fa-angle-right"></i>
                </a>
                </div> */}
            </div>
            <div className="all-tramitesmig">
              <div className="all-tramitesmig__box " data-aos="fade-right" data-aos-duration="4000">
              <a href="#form_section">
                  <i className="fa-solid"></i>
                <h1>Parole Humanitario</h1>
                </a>
                <p>
                Es un Permiso para Personas que viven fuera 
              de Estados Unidos y puedan solicitar un permiso 
              de permanencia temporal (“parole”) 
              en Estados Unidos por razones humanitarias 
              urgentes o de beneficio público significativo.
                </p>
                <div className="all-tramitesmig__box__name">
                  <div className="all-tramitesmig__box__name__profile1">
                  
               
                    <img src={Img1} alt="user_img" />
                    
             
                  </div>
                </div>
              </div> 

               <div className="all-tramitesmig__box" data-aos="fade-left" data-aos-duration="4000">
               <a href="#form_section">
                  <i className="fa-solid"></i>
                  <h1>Permisos de Trabajos</h1>
                  </a>
                <p>
                Una persona que  no es ciudadano estadounidense 
                o residente permanente legal, debe evidenciar que 
                puede trabajar en Estados Unidos mediante la presentación 
                de un <a  href="https://www.uscis.gov/es/trabajar-en-los-estados-unidos/informacion-para-empleadores-y-empleados/empleadores-informacion-del-empleador/autorizacion-de-empleo"> Documento de Autorización de Empleo (Formulario I-766/EAD, por sus siglas en inglés).</a> 
                
                <br/>
                <br/>
                Puede solicitar un EAD si es elegible y existen los siguientes tipos: <br/>
                •	EAD Inicial: Este documento demuestra que usted está autorizado a trabajar en Estados Unidos.<br/>  
                •	EAD de Renovación: Este documento renueva su EAD inicial. Por lo general, usted no debe solicitar 
                la renovación de su EAD más de 180 días antes de que caduque su EAD original.<br/>
                •	EAD de Reemplazo: Este documento remplaza un EAD que se le haya perdido, mutilado o le haya sido robado. 
                Un EAD de remplazo también remplaza un EAD que se haya expedido con información incorrecta, como, por ejemplo, un nombre escrito erróneamente.

                </p>
                <div className="all-tramitesmig__box__name">
                  <div className="all-tramitesmig__box__name__profile1-2">
                    <img src={Img2} alt="user_img" />
                    
                  </div>
                </div>
              </div> 
              </div>
              <div className="all-tramitesmig">
              <div className="all-tramitesmig__box" data-aos="fade-right" data-aos-duration="4000">
              <a href="#form_section">
                  <i className="fa-solid"></i>
                <h1>Cambios de Dirección</h1>
                </a>
                <p>
                Si usted es aplicante aun proceso dentro de 
                USCIS, siempre se requiere una dirección física 
                de correo donde le estarán enviando las actualizaciones 
                y documentación correspondiente con su caso, si usted se 
                cambia de dirección, a la brevedad posible hay que cambiar 
                su dirección con USCIS para asegurarse de recibir toda la 
                correspondencia y los beneficios de manera oportuna y evitar 
                posibles retrasos relacionados con su caso.
                </p>
                <div className="all-tramitesmig__box__name">
                  <div className="all-tramitesmig__box__name__profile2-2">
                    <img src={Img3} alt="user_img" />
                    
                  </div>
                </div>
              </div> 
              <div className="all-tramitesmig__box" data-aos="fade-left" data-aos-duration="4000">
              <a href="#form_section">
                  <i className="fa-solid"></i>
                <h1>TPS</h1>
                 </a>
                <p>
                El Estatus de Protección Temporal (TPS) es asignado 
                debido a condiciones en un país extranjero, que impiden 
                a los ciudadanos del país regresar a su país temporalmente 
                de manera segura. USCIS podría otorgar TPS a los nacionales 
                elegibles de ciertos países (o partes de estos) que ya están 
                en Estados Unidos. <br/><br/>

                Se podría designar un país al TPS debido a las siguientes condiciones temporales:
                <br/>
                •	Conflicto armado en curso (tal como una guerra civil) <br/>
                •	Un desastre natural (tal como un terremoto o huracán) o una epidemia<br/>
                •	Otras condiciones extraordinarias y de carácter temporal.

                </p>
                
                <div className="all-tramitesmig__box__name">
                  <div className="all-tramitesmig__box__name__profile2">
                    <img src={Img4} alt="user_img" />
                    
                  </div>
                </div>
              </div> 
              </div>
              <div className="all-tramitesmig">
              <div className="all-tramitesmig__box" data-aos="fade-right" data-aos-duration="4000">
              <a href="#form_section">
                  <i className="fa-solid"></i>
                <h1>Peticiones Familiares - Ajuste Cubano</h1>
                </a>
                <p>
                Este trámite lo pueden hacer ciudadanos o 
                residentes permanentes legales (LPR) de 
                Estados Unidos para ayudar a un familiar a 
                convertirse en residente permanente legal de 
                Estados Unidos al obtener lo que frecuentemente 
                llaman “Tarjeta Verde”. Para hacerlo, necesitará 
                patrocinar al familiar y poder demostrar que tiene 
                una relación filiatoria, que tiene ingresos o bienes 
                suficientes para mantener económincamente a su(s) 
                familiar(es) cuando llegue(n) a Estados Unidos
                </p>
                <div className="all-tramitesmig__box__name">
                  <div className="all-tramitesmig__box__name__profile3">
                    <img src={Img5} alt="user_img" />
                    
                  </div>
                </div>
              </div> 

                          <div className="all-tramitesmig__box" data-aos="fade-left" data-aos-duration="4000">
                          <a href="#form_section">
                              <i className="fa-solid"></i>
                              <h1>Asilos Políticos</h1>
                              </a>
                              <p>
                              Usted puede solicitar asilo si está físicamente 
                              en Estados Unidos y no es ciudadano estadounidense.
                              <br/><br/>
                              •	La solicitud de asilo afirmativo si no 
                                está en procedimientos de remoción con 
                                la Oficina Ejecutiva de Revisión de Casos 
                                de Inmigración (EOIR).<br/>
                              •	La solicitud de defensa de asilo si está 
                                en procedimientos de remoción ante un juez 
                                de inmigración o la Junta de Apelaciones de 
                                Inmigración (BIA) de EOIR. Consulte la sección 
                                Dónde Presentar la Solicitud a continuación para 
                                obtener sobre cómo saber si está en procedimientos 
                                de remoción ante EOIR.<br/><br/>
                                Si usted no presenta el Formulario I-589 dentro del plazo de un año a partir de su llegada a Estados Unidos, no será elegible para solicitar asilo bajo la sección 208(a)(2)(B) de la Ley de Inmigración y Nacionalidad (INA).

                              </p>
                              <div className="all-tramitesmig__box__name">
                                  <div className="all-tramitesmig__box__name__profile3-2">
                                      <img src={Img6} alt="user_img" />

                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="all-tramitesmig">
              <div className="all-tramitesmig__box" data-aos="fade-right" data-aos-duration="4000">
              <a href="#form_section">
                  <i className="fa-solid"></i>
                <h1>Aplicación Para Residencia</h1>
                </a>
                <p>
                Tener una Tarjeta Verde o Green Card 
                (conocida oficialmente como Tarjeta de Residente Permanente) 
                le permite vivir y trabajar permanentemente en 
                Estados Unidos. Los pasos que deberá tomar 
                para solicitar una Tarjeta Verde variarán 
                según su situación individual.
                </p>
                <div className="all-tramitesmig__box__name">
                  <div className="all-tramitesmig__box__name__profile4">
                    <img src={Img7} alt="user_img" />
                    
                  </div>
                </div>
              </div> 

              

              <div className="all-tramitesmig__box"data-aos="fade-left" data-aos-duration="4000">
              <a href="#form_section">
                  <i className="fa-solid"></i>
                <h1>Visa de Fiance</h1>
                </a>
                <p>
                Si es ciudadano de EE. UU. y quiere traer 
                a su prometido (a) extranjero (a) a 
                Estados Unidos para contraer matrimonio 
                deberá presentar una  Petición de Prometido(a)  extranjero(a). 
                <br/><br/>
                Para obtener una visa de prometido (a), 
                el solicitante y su prometido (a) deben 
                tener la intensión de contraer matrimonio 
                 de 90 días luego de que su prometido (a) 
                 sea admitido a EE. UU.
                </p>
                <div className="all-tramitesmig__box__name">
                  <div className="all-tramitesmig__box__name__profile4-2">
                    <img src={Img8} alt="user_img" />
                    
                  </div>
                </div>
              </div> 
              <div className="all-tramitesmig__box" data-aos="fade-right" data-aos-duration="4000">
              <a href="#form_section">
                  <i className="fa-solid"></i>
                <h1>Aplicacion para Ciudadanía</h1>
                </a>
                <p>
                La naturalización es el proceso para 
                convertirse en ciudadano estadounidense 
                si nació fuera de Estados Unidos. Si cumple 
                con ciertos requisitos, podrá convertirse 
                en ciudadano estadounidense por el nacimiento o 
                después de su nacimiento
                </p>
                <div className="all-tramitesmig__box__name">
                  <div className="all-tramitesmig__box__name__profile4-2">
                    <img src={Img9} alt="user_img" />
                    
                  </div>
                </div>
              </div> 
              </div>
          </div>
        </div>

      </section>

      <section id="form_section">

<div className="servicios-content__title">
               
               <h2>Solicita el Servicio</h2>
               <p>
                 LLena el formulario con tus datos y te contactaremos a la brevedad
               </p>
             </div>

             </section>
          <form class="row g-3" action="https://formsubmit.co/TramitesegurosUSA@gmail.com" method="POST">
               <div class="col-md-6">
                 <label for="inputname" class="form-label">Nombre</label>
                 <input type="name" name="Nombre"  class="form-control" id="inputname" />
               </div>
               <div class="col-md-6">
                 <label for="inputlastname" class="form-label">Apellido</label>
                 <input type="lastname" name="Apellido" class="form-control" id="lastname" />
               </div>
               <div class="col-md-6">
                 <label for="inputEmail" class="form-label">Correo</label>
                 <input type="email" name="Email" class="form-control" id="email" />
               </div>
               <div class="col-md-6">
                 <label for="inputAddress" class="form-label">Estado</label>
                 <input type="text" name="Estado" class="form-control" id="inputAddress"  />
               </div>
               <div class="col-md-6">
                 <label for="inputAddress2" class="form-label">Ciudad</label>
                 <input type="text" name="Ciudad" class="form-control" id="inputAddress2" />
               </div>
               <div class="col-md-6">
                 <label for="inputCity" class="form-label">Telefono</label>
                 <input type="text" name="Telefono" class="form-control" id="inputCity" />
               </div>
               <div class="col-md-4">
                 <label for="inputState" class="form-label">Servicio Solicitado</label>
                 <select id="inputState" name="Tramite" class="form-select">
                   <option selected>Selecione un servicio</option>
                   <option value="Parole Humanitario" >Parole Humanitario</option>
                   <option  value="Permisos de Trabajos" >Permisos de Trabajos</option>
                   <option  value="Cambios de Dirección" >Cambios de Dirección</option>
                   <option  value="TPS" >TPS</option>
                   <option  value="Peticiones Familiares - Ajuste Cubano" >Peticiones Familiares - Ajuste Cubano</option>
                   <option  value="Aplicación Para Residencia" >Aplicación Para Residencia</option>
                   <option  value="Visa de Fiance" >Visa de Fiance</option> 
                   <option  value="Aplicacion para Ciudadanía" >Aplicacion para Ciudadanía</option> 

                 </select>
               </div>
               <div class="col-12">
                 <div class="form">
                 </div>
               </div>
               <div class="col-12">
                 <button type="submit" class="btn btn-primary">Enviar</button>
                 <input type="hidden" name="_next" value="https://tramitesseguro.com/#/tramitesmig"/>
                <input type="hidden" name="_captcha" value="false"/>
               </div>
             </form>
           
          
        
      
    </>
  );
}

export default Tramitesmig;
